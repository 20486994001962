<script setup lang="ts">
import { HoldEventResource } from '@/types/hold-events';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { ref } from 'vue';
import { getItemFromArrayBasedOnId, sortArrayBy } from '@/util/globals';
import { useToast } from 'vue-toastification';
import { InviteResource } from '@/types/invite';
import { formatStampAsTime } from '@/util/timeFunctions';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

type Props = {
  invite: InviteResource;
  initialDate?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  initialDate: null,
});

const emit = defineEmits<{
  (event: 'accepted'): void;
  (event: 'closed'): void;
}>();

const modalOpen = ref(true);
const selectedDate = ref(null);
const holdEvent = ref<HoldEventResource | null>(props.invite.hold_event);

const acceptHoldEvent = async () => {
  if (!selectedDate.value) return;
  if (!holdEvent.value) return;
  await axios.post(`/api/hold-events/${holdEvent.value.id}/accept`, {
    date: selectedDate.value,
  });
  emit('accepted');
};

const setup = () => {
  if (!props.invite || !props.invite.hold_event || !holdEvent.value) {
    modalOpen.value = false;
    emit('closed');
    return;
  }
  if (props.initialDate) {
    const item = getItemFromArrayBasedOnId(props.initialDate, holdEvent.value.hold_event_date_pivots, null, 'date');
    if (item) {
      selectedDate.value = props.initialDate;
      return;
    }
  }
  const possibleDates = sortArrayBy(
    holdEvent.value.hold_event_date_pivots.filter((p) => p.possible),
    'primary',
    false
  );
  if (possibleDates.length > 0) {
    selectedDate.value = possibleDates[0].date;
  } else if (holdEvent.value.hold_event_date_pivots.length > 0) {
    selectedDate.value = holdEvent.value.hold_event_date_pivots[0].date;
  } else {
    useToast().info('No Potential Dates Given');
  }
};
setup();
</script>
<template>
  <CrudModal
    v-if="modalOpen && holdEvent"
    create-button-text="Accept"
    :disabled="!selectedDate"
    title="Confirm Hold"
    @create="acceptHoldEvent"
    @closed="[$emit('closed'), (modalOpen = false)]">
    <div class="grid grid-cols-2">
      <div>
        <InputLabel
          super-text
          label="When"></InputLabel>
        {{ formatStampAsTime(invite.start) }} -
        {{ formatStampAsTime(invite.end) }}
      </div>
    </div>
    <div class="mt-edge">
      <InputLabel
        super-text
        label="Select a Date" />
      <div class="grid grid-cols-3 gap-edge">
        <div
          v-for="pivot in holdEvent.hold_event_date_pivots"
          :class="{ 'text-textColor-soft': !pivot.possible, '!border-success': pivot.date === selectedDate }"
          class="border px-edge py-3 rounded text-center hover:border-textColor cursor-pointer"
          @click="selectedDate = pivot.date">
          <i
            :class="{ 'fa-check-square': pivot.possible, 'fa-square': !pivot.possible }"
            :title="pivot.possible ? 'Date has been marked as possible' : 'Date is not marked as possible'"
            class="fa fa-fw fa-regular"></i>
          {{ pivot.date }}
        </div>
      </div>
    </div>
  </CrudModal>
</template>

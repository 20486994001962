<script lang="ts" setup>
import InputErrorText from '@/components/Inputs/InputLabels/InputErrorText.vue';
import InputHelperText from '@/components/Inputs/InputLabels/InputHelperText.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { blurActiveElement, createUuId } from '@/util/globals';
import { computed, nextTick, onMounted, ref } from 'vue';
import DataList from '@/components/Inputs/Components/DataList.vue';

export type LabelPlacement = 'top' | 'left' | 'right' | 'bottom';

type Props = {
  modelValue: string | null | number;
  leftIcon?: string | null;
  rightIcon?: string | null;
  loading?: boolean;
  withClear?: boolean;
  alwaysShowClear?: boolean;
  errorMessage?: string | null;
  wrapperClass?: string | null;
  canEdit?: boolean;
  maxLength?: number;
  dataListOptions?: string[];
  isHidden?: boolean;
  required?: boolean;
  label?: string | null;
  labelTitle?: string | null;
  helperText?: string | null;
  labelPlacement?: LabelPlacement;
  inputWrapperClasses?: string | null;
  placeholder?: string;
  inputClassName?: string | null;
  inputType?: string;
  setFocus?: boolean;
  focus?: boolean;
  minLength?: number;
  square?: boolean;
  forceLowerCase?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  leftIcon: null,
  rightIcon: null,
  loading: false,
  withClear: false,
  alwaysShowClear: false,
  errorMessage: null,
  wrapperClass: null,
  canEdit: true,
  maxLength: 255,
  dataListOptions: () => [],
  isHidden: false,
  required: false,
  label: null,
  labelTitle: null,
  helperText: null,
  labelPlacement: 'top',
  inputWrapperClasses: null,
  placeholder: '',
  inputClassName: null,
  inputType: 'text',
  setFocus: false,
  focus: false,
  minLength: undefined,
  square: false,
  forceLowerCase: false,
});

const emit = defineEmits<{
  (event: 'clear', arg: string | number | null): void;
  (event: 'clickIconRight'): void;
  (event: 'update:modelValue', arg: string | number): void;
  (event: 'update:focus', arg: boolean): void;
  (event: 'blur', arg: string | number): void;
  (event: 'leftIconClick'): void;
  (event: 'dataListSelected'): void;
  (event: 'paste', arg: ClipboardEvent): void;
}>();

const uuid = createUuId();

const classes = computed(() => {
  const allClasses = [props.inputClassName];
  if (props.errorMessage !== null) {
    allClasses.push('ring-warning');
  } else if (props.isHidden) {
    allClasses.push('ring-transparent');
  } else if (!props.isHidden) {
    if (props.canEdit) {
      allClasses.push(' bg-inputs-background ');
    } else {
      allClasses.push(' bg-inputs-disabledBackground ');
    }
  }
  return allClasses.join(' ');
});

const pos = computed(() => {
  switch (props.labelPlacement.toLowerCase()) {
    case 'left': {
      return 'gap-3';
    }
    default: {
      return 'flex-col';
    }
  }
});

const inFocus = ref(false);

const onBlur = (event: FocusEvent) => {
  const target = event.target as HTMLInputElement;

  setTimeout(() => {
    inFocus.value = false;
  }, 250);

  emit('blur', target.value);
  emit('update:focus', false);
};

const onFocus = () => {
  inFocus.value = true;
  emit('update:focus', inFocus.value);
};

const input = ref<HTMLInputElement | null>(null);
const baseinputwrapper = ref<HTMLElement | null>(null);
const inputwrapper = ref<HTMLElement | null>(null);

defineExpose({
  input,
  inputwrapper,
  wrapper: baseinputwrapper,
});

onMounted(async () => {
  if (props.setFocus) {
    setTimeout(() => {
      if (input.value) {
        input.value.focus();
      }
    }, 250);
    setTimeout(() => {
      if (input.value && !inFocus.value) {
        input.value.focus();
      }
    }, 500);
    setTimeout(() => {
      if (input.value && !inFocus.value) {
        input.value.focus();
      }
    }, 1000);
  }
});

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (props.forceLowerCase) {
    emit('update:modelValue', target.value.toLowerCase());
    return;
  }
  emit('update:modelValue', target.value);
};
const currentDataListOption = ref(null);

const selectDataListOption = (newValue) => {
  emit('update:modelValue', newValue);
  emit('blur', newValue);
  nextTick(() => {
    emit('dataListSelected', newValue);
  });
  inFocus.value = false;
  blurActiveElement();
};

const onEnterClick = (e: KeyboardEvent) => {
  if (currentDataListOption.value !== null) {
    selectDataListOption(currentDataListOption.value);
  }
};

defineOptions({
  inheritAttrs: false,
});

const onKeyDownPress = (e: Event) => {
  if (props.forceLowerCase) {
    const target = e.target as HTMLInputElement;
    target.value = target.value.toLowerCase();
  }
};
</script>

<template>
  <div
    ref="baseinputwrapper"
    :class="[pos, wrapperClass]"
    class="flex">
    <InputLabel
      :is-hidden="isHidden"
      :label="label"
      :mandatory-text="required ? 'Required' : null"
      :title="labelTitle" />
    <div class="relative">
      <div
        ref="inputwrapper"
        :class="[classes, { 'rounded-[6px]': !square }]"
        :data-can-edit="canEdit"
        :data-is-focus="inFocus"
        class="group relative flex items-center overflow-hidden ring-1 ring-borderColor focus-within:!ring-highlight data-[can-edit=true]:hover:ring-textColor-soft">
        <div
          v-if="$slots.start || leftIcon"
          class="slot-start-wrapper flex h-full w-[35px] items-center justify-center !p-0">
          <slot name="start">
            <i
              v-if="leftIcon"
              :class="leftIcon + (inFocus ? ' text-textColor ' : ' text-textColor-soft ')"
              class="fa fa-fw"
              @click="$emit('leftIconClick')" />
          </slot>
        </div>

        <input
          ref="input"
          :class="[$slots.start || leftIcon ? ' !pl-[4px] ' : '', { 'lowercase': forceLowerCase }]"
          :disabled="!canEdit"
          :list="uuid"
          :maxlength="maxLength"
          :minlength="minLength"
          :placeholder="placeholder"
          :type="inputType"
          :value="modelValue"
          :is-hidden="isHidden"
          class="clear-autofill w-full flex-1 border-none bg-transparent text-base focus:ring-0 disabled:cursor-not-allowed"
          v-bind="$attrs"
          @paste="$emit('paste', $event)"
          @keydown="onKeyDownPress"
          @keydown.enter="onEnterClick"
          @blur="onBlur($event)"
          @focus="onFocus()"
          @input="onInput($event)" />

        <DataList
          v-model:current-data-list-option="currentDataListOption"
          :in-focus="inFocus"
          :model-value="modelValue"
          :data-list-options="dataListOptions"
          :container="input"
          @update:model-value="selectDataListOption" />

        <div
          v-if="$slots.end || loading || withClear || rightIcon"
          :class="{ 'w-[35px]': !$slots.end, 'bg-inputs-disabledBackground': !canEdit }"
          class="flex h-full items-center justify-center !p-0 text-textColor-soft hover:text-textColor">
          <slot name="end">
            <i
              v-if="loading"
              class="fa fa-fw fa-circle-o-notch animate-spin" />
            <i
              v-else-if="withClear && (canEdit || alwaysShowClear)"
              v-show="isHidden ? inFocus && String(modelValue)?.length : true"
              class="fa fa-fw fa-times cursor-pointer"
              @click.stop="[$emit('update:modelValue', null), $emit('clear', modelValue)]" />
            <i
              v-else-if="rightIcon"
              :class="rightIcon"
              class="fa fa-fw" />
          </slot>
        </div>
      </div>
      <InputErrorText
        v-if="!!errorMessage"
        :text="errorMessage"
        class="mt-1" />
      <InputHelperText
        v-if="!!helperText"
        :text="helperText"
        class="mt-1" />
    </div>
  </div>
</template>

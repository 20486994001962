<script lang="ts" setup>
import BaseInput from '@/components/Base/BaseInput.vue';
import { onClickOutside } from '@vueuse/core';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

type Props = {
  modelValue: string | null;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  icon?: string;
  labelClasses?: string;
  wrapperClasses?: string;
  canEdit?: boolean;
  withSeconds?: boolean;
  isHidden?: boolean;
  setFocus?: boolean;
  withIcon?: boolean;
  inputClass?: string;
  leftIcon?: string;
  maxHours?: number;
  withClear?: boolean;
  emitOnClear?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  label: '',
  placeholder: '__:__',
  disabled: false,
  canEdit: true,
  required: false,
  error: '',
  icon: '',
  labelClasses: '',
  wrapperClasses: '',
  withSeconds: false,
  withIcon: true,
  setFocus: false,
  isHidden: false,
  inputClass: '',
  leftIcon: 'fa-clock fa-regular',
  maxHours: 23,
  withClear: true,
  emitOnClear: true,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'blur', value: string | null): void;
  (e: 'clickedOutside'): void;
  (e: 'clear'): void;
  (e: 'focus'): void;
  (e: 'keydown-enter'): void;

  // To remove error message FieldsList.vue
  (e: 'documentUploaded', value: string | null): void;
  (e: 'document-uploaded', value: string | null): void;
}>();

const time = ref<string | null>(null);
if (
  props.modelValue !== null &&
  props.modelValue !== '' &&
  props.modelValue !== undefined &&
  props.modelValue !== false &&
  props.modelValue !== 'false'
) {
  time.value = props.modelValue;
}
if (props.withSeconds && time.value?.length === 5) {
  time.value += ':00';
} else if (!props.withSeconds && time.value?.length === 8) {
  time.value = time.value?.substring(0, 5) ?? null;
}

const validTimeFormat = (time: string) => {
  // if max hours is more than 24 use valid regex

  if (props.maxHours > 24) {
    if (props.withSeconds) {
      return /^([0-9][0-9]|[0-9]):[0-5][0-9]:[0-5][0-9]$/.test(time);
    }
    // if without seconds, then it must be in the format of 00:00
    return /^([0-9][0-9]|[0-9]):[0-5][0-9]$/.test(time);
  }

  // if with seconds, then it must be in the format of 00:00:00
  if (props.withSeconds) {
    return /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(time);
  }
  // if without seconds, then it must be in the format of 00:00
  return /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(time);
};

const isValid = ref(true);

if (time.value !== null && time.value !== '') {
  isValid.value = validTimeFormat(time.value);
}

const inFocus = ref(false);

watch(time, (val) => {
  if (!val && props.required) return;
  if (validTimeFormat(val)) {
    emit('update:modelValue', val);
  } else {
    emit('update:modelValue', null);
  }
});

watch(
  () => props.modelValue,
  (value) => {
    if (inFocus.value) return;
    time.value = value;
  }
);

const input = ref<{ input: HTMLElement | null } | null>(null);

const showDropDown = ref(false);
const yPos = ref(0);
const xPos = ref(0);
const theWidth = ref(150);
const finishedScrolling = ref(false);
const dropdown = ref<HTMLElement | null>(null);

const times = ref<string[]>([]);
const setTimes = () => {
  times.value = [];
  if (props.maxHours > 24) {
    for (let i = 0; i < props.maxHours; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hour = i < 10 ? `0${i}` : i;
        const minute = j < 10 ? `0${j}` : j;
        times.value.push(`${hour}:${minute}`);
      }
      // const hour = i < 10 ? `0${i}` : i;
      // times.value.push(`${hour}:00`);
    }
    return;
  }
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      const hour = i < 10 ? `0${i}` : i;
      const minute = j < 10 ? `0${j}` : j;
      times.value.push(`${hour}:${minute}`);
    }
  }
};
setTimes();

const scrollTimeIntoView = () => {
  setTimeout(() => {
    if (props.modelValue) {
      const closestTime = times.value.reduce((prev, curr) => {
        return Math.abs(Number(curr.replace(':', '')) - Number(time.value?.replace(':', ''))) <
          Math.abs(Number(prev.replace(':', '')) - Number(time.value?.replace(':', '')))
          ? curr
          : prev;
      });
      const closestTimeElement = dropdown.value?.querySelector(`[data-time="${closestTime}"]`);
      if (closestTimeElement) {
        closestTimeElement.scrollIntoView({ behavior: 'instant', block: 'center' });
        finishedScrolling.value = true;
      } else {
        const twelve = dropdown.value?.querySelector(`[data-time="12:00"]`);
        if (twelve) {
          twelve.scrollIntoView({ behavior: 'instant', block: 'center' });
          finishedScrolling.value = true;
        }
      }
    } else {
      const twelve = dropdown.value?.querySelector(`[data-time="12:00"]`);
      if (twelve) {
        twelve.scrollIntoView({ behavior: 'instant', block: 'center' });
        finishedScrolling.value = true;
      }
    }
  }, 50);
};
const onClick = (event: MouseEvent) => {
  if (!props.withSeconds) {
    showDropDown.value = true;
    if (input.value) {
      yPos.value = input.value.input.getBoundingClientRect().y + 43;
      xPos.value = input.value.input.getBoundingClientRect().x - 35;
      theWidth.value = input.value.input.parentElement.getBoundingClientRect().width;

      scrollTimeIntoView();
    }
  }

  const toBeginning = (selectionArray?: [number, number]) =>
    event.target.setSelectionRange(...(selectionArray ? selectionArray : [0, 1]));

  if (props.withSeconds && time.value?.length !== 8) return toBeginning();
  if (!props.withSeconds && time.value?.length !== 5) return toBeginning();
  // select next char
  const start = (event.target as HTMLInputElement)?.selectionStart;
  const end = (event.target as HTMLInputElement)?.selectionEnd;
  if (end - start > 1) return toBeginning();

  if (start === end) {
    if (start === 5) return toBeginning();
    event.target.setSelectionRange(start, start + 1);

    if (event.target.selectionStart === 2) return toBeginning([1, 2]);
    else return;
  }
  // event.target.setSelectionRange(0, 0);
  event.target.setSelectionRange(end, end + 1);
  if (event.target.selectionStart === 2) return toBeginning();
};

const onUpdateTime = (event: KeyboardEvent) => {
  isValid.value = true;

  if (event.key === 'Tab') {
    onBlur(time.value);
    return;
  }

  event.preventDefault();
  scrollTimeIntoView();
  if (event.key === 'Enter') {
    onBlur(time.value);
    (document.activeElement as HTMLElement)?.blur();
    showDropDown.value = false;
    emit('keydown-enter');
    return;
  }

  if (event.key === 'Backspace' && time.value) {
    time.value = time.value.slice(0, -1);
    return;
  }

  // select char to the left if key press is left arrow
  if (event.key === 'ArrowLeft') {
    const input = event.target as HTMLInputElement;
    // if char to the left is a colon, move cursor to the left again
    if (input.selectionStart === 3) {
      input.setSelectionRange(1, 2);
    } else if (input.selectionStart === 6) {
      input.setSelectionRange(4, 5);
    } else {
      // if selectionStart is 0, do nothing
      if (input.selectionStart === 0) return;

      input.setSelectionRange(input.selectionStart - 1, input.selectionStart);
    }
    return;
  }

  // select char to the right if key press is right arrow
  if (event.key === 'ArrowRight') {
    const input = event.target as HTMLInputElement;
    // if char to the right is a colon, move cursor to the right again
    if (input.selectionStart === 1) {
      input.setSelectionRange(3, 4);
    } else if (input.selectionStart === 4) {
      input.setSelectionRange(6, 7);
    } else {
      // if selectionStart is 7, do nothing
      if (input.selectionStart === 7) return;

      input.setSelectionRange(input.selectionStart + 1, input.selectionStart + 2);
    }
    return;
  }

  // if key press is arrow up and with second and no char is selected increment seconds
  if (event.key === 'ArrowUp') {
    if (!time.value) return;

    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    if (props.withSeconds && start === 8 && end === 8) {
      const seconds = Number(time.value.slice(6, 8));
      if (seconds === 59) {
        // if add 1 minute to minutes
        const minutes = Number(time.value.slice(3, 5));
        if (minutes === 59) {
          // if add 1 hour to hours
          const hours = Number(time.value.slice(0, 2));
          if (hours === 23) {
            // if add 1 day to days
            time.value = '00:00:00';
          } else {
            time.value = `${hours + 1}:00:00`;
          }
        } else {
          // add 0 to minutes if less than 10
          if (minutes < 9) {
            time.value = `${time.value.slice(0, 3)}0${minutes + 1}:00`;
          } else {
            time.value = `${time.value.slice(0, 3)}${minutes + 1}:00`;
          }
        }
      } else {
        time.value = time.value.slice(0, 6) + (seconds < 9 ? `0${seconds + 1}` : seconds + 1);
      }
      return;
    }

    if (!props.withSeconds && start === 5 && end === 5) {
      const minutes = Number(time.value.slice(3, 5));
      if (minutes === 59) {
        // if add 1 hour to hours
        const hours = Number(time.value.slice(0, 2));
        if (hours === 23) {
          // if add 1 day to days
          time.value = '00:00';
        } else {
          time.value = `${hours + 1}:00`;
        }
      } else {
        // add 0 to minutes if less than 10
        if (minutes < 9) {
          time.value = `${time.value.slice(0, 3)}0${minutes + 1}`;
        } else {
          time.value = `${time.value.slice(0, 3)}${minutes + 1}`;
        }
      }
      return;
    }

    // if first char is selected increment to correct value and keep cursor in place
    if (start === 0 && end === 1) {
      const firstChar = Number(time.value.slice(0, 1));
      if (firstChar === 2) {
        time.value = `0${time.value.slice(1)}`;
      } else {
        // if firstChar + 1 is 2 check if next char is more than 3
        if (firstChar + 1 === 2) {
          const secondChar = Number(time.value.slice(1, 2));
          if (secondChar > 3) {
            time.value = `23${time.value.slice(2)}`;
          } else {
            time.value = `${firstChar + 1}${time.value.slice(1)}`;
          }
        } else {
          time.value = `${firstChar + 1}${time.value.slice(1)}`;
        }
      }
      nextTick(() => {
        input.setSelectionRange(0, 1);
      });
      return;
    }

    // if second char is selected increment to correct value and keep cursor in place
    if (start === 1 && end === 2) {
      const firstChar = Number(time.value.slice(0, 1));
      const secondChar = Number(time.value.slice(1, 2));
      if (firstChar === 2) {
        if (secondChar === 3) {
          time.value = `00${time.value.slice(2)}`;
        } else {
          time.value = `${firstChar}${secondChar + 1}${time.value.slice(2)}`;
        }
      } else if (secondChar === 9) {
        time.value = `${firstChar + 1}0${time.value.slice(2)}`;
      } else {
        time.value = `${firstChar}${secondChar + 1}${time.value.slice(2)}`;
      }
      nextTick(() => {
        input.setSelectionRange(1, 2);
      });
      return;
    }

    // if third char is selected increment to correct value and keep cursor in place
    if (start === 3 && end === 4) {
      const thirdChar = Number(time.value.slice(3, 4));
      if (thirdChar === 5) {
        time.value = `${time.value.slice(0, 3)}0${time.value.slice(4)}`;
      } else {
        time.value = `${time.value.slice(0, 3)}${thirdChar + 1}${time.value.slice(4)}`;
      }
      nextTick(() => {
        input.setSelectionRange(3, 4);
      });
      return;
    }

    // if fourth char is selected increment to correct value and keep cursor in place
    if (start === 4 && end === 5) {
      const thirdChar = Number(time.value.slice(3, 4));
      const fourthChar = Number(time.value.slice(4, 5));
      if (thirdChar === 5) {
        if (fourthChar === 9) {
          time.value = `${time.value.slice(0, 3)}00${time.value.slice(5)}`;
        } else {
          time.value = `${time.value.slice(0, 3)}${thirdChar}${fourthChar + 1}${time.value.slice(5)}`;
        }
      } else if (fourthChar === 9) {
        time.value = `${time.value.slice(0, 3)}${thirdChar + 1}0${time.value.slice(5)}`;
      } else {
        time.value = `${time.value.slice(0, 3)}${thirdChar}${fourthChar + 1}${time.value.slice(5)}`;
      }
      nextTick(() => {
        input.setSelectionRange(4, 5);
      });
      return;
    }

    // if with seconds and fifth char is selected increment to correct value and keep cursor in place

    if (!props.withSeconds) return;

    if (input.selectionStart === 6 && input.selectionEnd === 7) {
      const fifthChar = Number(time.value.slice(6, 7));
      if (fifthChar === 5) {
        time.value = `${time.value.slice(0, 6)}0${time.value.slice(7)}`;
      } else {
        time.value = `${time.value.slice(0, 6)}${fifthChar + 1}${time.value.slice(7)}`;
      }
      nextTick(() => {
        input.setSelectionRange(6, 7);
      });
      return;
    }

    // if with seconds and sixth char is selected increment to correct value and keep cursor in place
    if (input.selectionStart === 7 && input.selectionEnd === 8) {
      const fifthChar = Number(time.value.slice(6, 7));
      const sixthChar = Number(time.value.slice(7, 8));
      if (fifthChar === 5) {
        if (sixthChar === 9) {
          time.value = `${time.value.slice(0, 6)}00${time.value.slice(8)}`;
        } else {
          time.value = `${time.value.slice(0, 6)}${fifthChar}${sixthChar + 1}${time.value.slice(8)}`;
        }
      } else if (sixthChar === 9) {
        time.value = `${time.value.slice(0, 6)}${fifthChar + 1}0${time.value.slice(8)}`;
      } else {
        time.value = `${time.value.slice(0, 6)}${fifthChar}${sixthChar + 1}${time.value.slice(8)}`;
      }
      nextTick(() => {
        input.setSelectionRange(7, 8);
      });
      return;
    }
  }

  if (event.key === 'ArrowDown') {
    if (!time.value) return;
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    if (props.withSeconds && start === 8 && end === 8) {
      const seconds = Number(time.value.slice(6, 8));
      if (seconds === 0) {
        // if subtract 1 minute to minutes
        const minutes = Number(time.value.slice(3, 5));
        if (minutes === 0) {
          // if subtract 1 hour to hours
          const hours = Number(time.value.slice(0, 2));
          if (hours === 0) {
            // if subtract 1 day to days
            time.value = '23:59:59';
          } else {
            time.value = `${hours - 1}:59:59`;
          }
        } else {
          // subtract 0 to minutes if less than 10
          if (minutes < 10) {
            time.value = `${time.value.slice(0, 3)}0${minutes - 1}:59`;
          } else {
            time.value = `${time.value.slice(0, 3)}${minutes - 1}:59`;
          }
        }
      } else {
        time.value = time.value.slice(0, 6) + (seconds <= 10 ? `0${seconds - 1}` : seconds - 1);
      }
      return;
    }

    if (!props.withSeconds && start === 5 && end === 5) {
      // subtract 1 minutes to time
      const minutes = Number(time.value.slice(3, 5));
      if (minutes === 0) {
        // if subtract 1 hour to hours
        const hours = Number(time.value.slice(0, 2));
        if (hours === 0) {
          // if subtract 1 day to days
          time.value = '23:59';
        } else if (hours - 1 < 10) {
          time.value = `0${hours - 1}:59`;
        } else {
          time.value = `${hours - 1}:59`;
        }
      } else {
        // subtract 0 to minutes if less than 10
        if (minutes < 10) {
          time.value = `${time.value.slice(0, 3)}0${minutes - 1}`;
        } else if (minutes - 1 < 10) {
          time.value = `${time.value.slice(0, 3)}0${minutes - 1}`;
        } else {
          time.value = `${time.value.slice(0, 3)}${minutes - 1}`;
        }
      }
      // if (minutes === 0) {
      //   // if subtract 1 hour to hours
      //   const hours = Number(time.value.slice(0, 2));
      //   if (hours === 0) {
      //     // if subtract 1 day to days
      //     time.value = '23:59';
      //   } else {
      //     time.value = `${hours - 1}:59`;
      //   }
      // } else {
      //   // subtract 0 to minutes if less than 10
      //   if (minutes < 10) {
      //     time.value = `${time.value.slice(0, 3)}0${minutes - 1}`;
      //   } else {
      //     time.value = `${time.value.slice(0, 3)}${minutes - 1}`;
      //   }
      // }
      return;
    }

    // if first char is selected decrement to correct value and keep cursor in place
    if (start === 0 && end === 1) {
      const firstChar = Number(time.value.slice(0, 1));
      const secondChar = Number(time.value.slice(1, 2));
      if (firstChar === 0) {
        if (secondChar > 3) {
          time.value = `1${secondChar - 1}${time.value.slice(2)}`;
        } else {
          time.value = `2${time.value.slice(1)}`;
        }
      } else {
        time.value = `${firstChar - 1}${time.value.slice(1)}`;
      }
      nextTick(() => {
        input.setSelectionRange(0, 1);
      });
      return;
    }

    // if second char is selected decrement to correct value and keep cursor in place
    if (start === 1 && end === 2) {
      const firstChar = Number(time.value.slice(0, 1));
      const secondChar = Number(time.value.slice(1, 2));
      if (secondChar === 0) {
        if (firstChar === 0) {
          const maxFirstChar = Number(props.maxHours.toString().slice(0, 1));
          const maxSecondChar = Number(props.maxHours.toString().slice(1, 2));
          time.value = `${maxFirstChar}${maxSecondChar}${time.value.slice(2)}`;
        } else {
          time.value = `${firstChar - 1}9${time.value.slice(2)}`;
        }
      } else {
        time.value = `${firstChar}${secondChar - 1}${time.value.slice(2)}`;
      }
      nextTick(() => {
        input.setSelectionRange(1, 2);
      });
      return;
    }

    // if third char is selected decrement to correct value and keep cursor in place
    if (start === 3 && end === 4) {
      const thirdChar = Number(time.value.slice(3, 4));
      if (thirdChar === 0) {
        time.value = `${time.value.slice(0, 3)}5${time.value.slice(4)}`;
      } else {
        time.value = `${time.value.slice(0, 3)}${thirdChar - 1}${time.value.slice(4)}`;
      }
      nextTick(() => {
        input.setSelectionRange(3, 4);
      });
      return;
    }

    // if fourth char is selected decrement to correct value and keep cursor in place
    if (start === 4 && end === 5) {
      const fourthChar = Number(time.value.slice(4, 5));
      if (fourthChar === 0) {
        const minutes = Number(time.value.slice(3, 4));
        if (minutes === 0) {
          time.value = `${time.value.slice(0, 3)}59${time.value.slice(5)}`;
        } else {
          time.value = `${time.value.slice(0, 3)}${minutes - 1}9${time.value.slice(5)}`;
        }
      } else {
        time.value = `${time.value.slice(0, 4)}${fourthChar - 1}${time.value.slice(5)}`;
      }
      nextTick(() => {
        input.setSelectionRange(4, 5);
      });
      return;
    }

    if (!props.withSeconds) return;

    // if fifth char is selected decrement to correct value and keep cursor in place
    if (start === 6 && end === 7) {
      const fifthChar = Number(time.value.slice(6, 7));
      if (fifthChar === 0) {
        time.value = `${time.value.slice(0, 6)}5${time.value.slice(7)}`;
      } else {
        time.value = `${time.value.slice(0, 6)}${fifthChar - 1}${time.value.slice(7)}`;
      }
      nextTick(() => {
        input.setSelectionRange(6, 7);
      });
      return;
    }

    // if sixth char is selected decrement to correct value and keep cursor in place
    if (start === 7 && end === 8) {
      const sixthChar = Number(time.value.slice(7, 8));
      if (sixthChar === 0) {
        const second = Number(time.value.slice(6, 7));
        if (second === 0) {
          time.value = `${time.value.slice(0, 6)}59`;
        } else {
          time.value = `${time.value.slice(0, 6)}${second - 1}9`;
        }
      } else {
        time.value = `${time.value.slice(0, 7)}${sixthChar - 1}${time.value.slice(8)}`;
      }
      nextTick(() => {
        input.setSelectionRange(7, 8);
      });
      return;
    }
  }

  // check if input is valid number and not a letter
  if (event.key.match(/^[0-9]$/)) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    if (start !== null && end !== null && end - start > 1) {
      time.value = '';
    }

    if (input && time.value?.length) {
      // if char is selected replace char with new value if valid value
      const start = input.selectionStart;
      const end = input.selectionEnd;
      if (start !== null && end !== null && end - start === 1 && start !== 2 && start !== 5) {
        // replace char with new value if valid value 1_:__:__
        const maxHourFirstChar = props.maxHours.toString().slice(0, 1);
        const maxHourSecondChar = props.maxHours.toString().slice(1, 2);

        if (start === 0 && event.key <= maxHourFirstChar) {
          if (Number(event.key) === 2) {
            if (Number(time.value.slice(1, 2)) > 3) {
              time.value = `${event.key}0${time.value.slice(2)}`;
            } else {
              time.value = `${event.key}${time.value.slice(1)}`;
            }
          } else {
            time.value = `${event.key}${time.value.slice(1)}`;
          }
          // select next char
          nextTick(() => {
            input.setSelectionRange(1, 2);
          });
          return;
        }

        // replace char with new value if valid value _1:__:__
        if (start === 1) {
          if (Number(time.value.slice(0, 1)) === 2) {
            if (event.key <= maxHourSecondChar) {
              time.value = `${time.value.slice(0, 1)}${event.key}${time.value.slice(2)}`;
              // keep selection in place
              nextTick(() => {
                input.setSelectionRange(3, 4);
              });
              return;
            }
          } else {
            time.value = `${time.value.slice(0, 1)}${event.key}${time.value.slice(2)}`;
            // keep selection in place
            nextTick(() => {
              input.setSelectionRange(3, 4);
            });
            return;
          }
        }

        // replace char with new value if valid value __:1_:__
        if (start === 3 && Number(event.key) < 6) {
          time.value = `${time.value.slice(0, 3)}${event.key}${time.value.slice(4)}`;
          nextTick(() => {
            input.setSelectionRange(4, 5);
          });
          return;
        }

        // replace char with new value if valid value __:_1:__
        if (start === 4) {
          time.value = `${time.value.slice(0, 4)}${event.key}${time.value.slice(5)}`;
          if (props.withSeconds) {
            nextTick(() => {
              input.setSelectionRange(6, 7);
            });
            return;
          }

          nextTick(() => {
            input.setSelectionRange(5, 5);
          });

          return;
        }

        // if not with second just return;
        if (!props.withSeconds) return;

        // replace char with new value if valid value __:__:1_
        if (start === 6 && Number(event.key) < 6) {
          time.value = `${time.value.slice(0, 6)}${event.key}${time.value.slice(7)}`;
          // keep selection in place
          nextTick(() => {
            input.setSelectionRange(7, 8);
          });
          return;
        }

        // replace char with new value if valid value __:__:_1
        if (start === 7) {
          time.value = `${time.value.slice(0, 7)}${event.key}${time.value.slice(8)}`;
          // keep selection in place
          nextTick(() => {
            input.setSelectionRange(9, 9);
          });
          return;
        }
      }
    }

    // check if max length is reached
    const maxLength = props.withSeconds ? 8 : 5;
    if (time.value?.length === maxLength) return;

    // Check if first number is valid hour
    if (time.value?.length === 0 && Number(event.key) > 2) return;

    // Check if second number is valid hour
    if (time.value?.length === 1 && Number(time.value + event.key) > 23) return;

    // Check if third number is valid minute
    if (time.value?.length === 3 && Number(event.key) > 5) return;

    // Check if fourth number is valid minute
    if (time.value?.length === 4 && Number(time.value.slice(3) + event.key) > 59) return;

    // if with second check if fifth number is valid second
    if (props.withSeconds && time.value?.length === 6 && Number(event.key) > 5) return;

    // if with second check if sixth number is valid second
    if (props.withSeconds && time.value?.length === 7 && Number(time.value.slice(6) + event.key) > 59) return;

    if (!time.value) {
      time.value = '';
    }

    time.value += event.key;
    // add colon after 2 numbers (4 if with seconds) are entered
    if (time.value?.length === 2 || (time.value?.length === 5 && props.withSeconds)) {
      time.value += ':';
    }
  }
};

const addTrailingZeroesToTime = (t: string) => {
  if (props.withSeconds) {
    if (!props.required && t.length === 0) return t;

    if (props.required && t.length === 0) return '00:00:00';

    switch (t.length) {
      case 1:
        return `${t}0:00:00`;
      case 2:
        return `${t}:00:00`;
      case 3:
        return `${t}00:00`;
      case 4:
        return `${t}0:00`;
      case 5:
        return `${t}:00`;
      case 6:
        return `${t}00`;
      case 7:
        return `${t}0`;
      case 8:
        return t;
      default: {
        return t;
      }
    }
  }

  if (!props.required && t.length === 0) return t;

  switch (t.length) {
    case 1:
      return `${t}0:00`;
    case 2:
      return `${t}:00`;
    case 3:
      return `${t}00`;
    case 4:
      return `${t}0`;
    case 5:
      return t;
    default: {
      return t;
    }
  }
};

const onClear = () => {
  if (props.required) return;
  if (props.emitOnClear) {
    time.value = null;
    emit('blur', null);
  } else {
    emit('clear');
  }
};

const listItemClicked = ref(false);

const onBlur = async (newVal: string) => {
  emit('clickedOutside');
  setTimeout(
    () => {
      if (listItemClicked.value) {
        listItemClicked.value = false;
        return;
      }
      inFocus.value = false;
      let newTime = newVal;
      if (!newVal && props.required) {
        if (props.modelValue && validTimeFormat(props.modelValue)) {
          newTime = props.modelValue;
        }
      }
      newTime = addTrailingZeroesToTime(newTime);
      time.value = newTime;
      if (newTime === props.modelValue) {
        return;
      }

      emit('blur', newTime);
    },
    showDropDown.value ? 200 : 0
  );
};

if (props.modelValue && !validTimeFormat(props.modelValue)) {
  emit('blur', null);
}
if (props.setFocus) {
  [0, 10, 20, 50].forEach((t) => {
    setTimeout(() => {
      input.value.input.focus();
      input.value.input.click();
      input.value.input.setSelectionRange(0, 1);
    }, t);
  });
}

onClickOutside(dropdown, () => {
  showDropDown.value = false;
});

const selectTime = (newTime: string) => {
  listItemClicked.value = true;
  inFocus.value = false;
  showDropDown.value = false;
  time.value = newTime;
  emit('blur', newTime);
};
const handleScroll = (event) => {
  if (!dropdown.value) return;
  if (!isMouseOverDropdown(event)) {
    showDropDown.value = false;
  }
};

const isMouseOverDropdown = (event) => {
  if (!dropdown.value) return;
  const dropdownRect = dropdown.value.getBoundingClientRect();
  const mouseX = event.clientX;
  const mouseY = event.clientY;
  return (
    mouseX >= dropdownRect.left &&
    mouseX <= dropdownRect.right &&
    mouseY >= dropdownRect.top &&
    mouseY <= dropdownRect.bottom
  );
};
onMounted(() => {
  window.addEventListener('wheel', handleScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('wheel', handleScroll);
});
</script>

<template>
  <BaseInput
    ref="input"
    :can-edit="!disabled && canEdit"
    :error-message="isValid ? null : 'Invalid time'"
    :input-class-name="inputClass"
    :wrapper-class="wrapperClasses"
    :is-hidden="isHidden"
    :label="label"
    :left-icon="leftIcon"
    :model-value="time"
    :placeholder="isHidden ? (inFocus ? placeholder : null) : placeholder"
    :required="required"
    :with-clear="!required && canEdit && !!modelValue && modelValue?.length > 0 && withClear"
    @blur="onBlur"
    @clear="onClear"
    @click="onClick"
    @focus="[(inFocus = true), emit('focus')]"
    @keydown="onUpdateTime($event)">
    <template
      v-if="$slots.end"
      #end>
      <slot name="end" />
    </template>
  </BaseInput>

  <div
    v-if="showDropDown"
    ref="dropdown"
    :class="finishedScrolling ? 'visible' : 'invisible'"
    :style="`left: ${xPos}px; top: ${yPos}px; width: ${theWidth}px;`"
    class="fixed border rounded h-[160px] overflow-auto bg-row-alternate z-[6000]">
    <ul>
      <li
        v-for="theTime in times"
        :key="theTime"
        :class="{ 'bg-row-hover': theTime === time }"
        :data-time="theTime"
        class="h-[30px] px-edge hover:bg-row-hover cursor-pointer flex items-center"
        @click="selectTime(theTime)">
        {{ theTime }}
      </li>
    </ul>
  </div>
</template>
